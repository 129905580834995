import React from "react";

function Footer() {
  return (
    <footer className="bg-dark text-white text-center py-4">
      <p>&copy; 2024 SE Boot Camp - Steven Moses Alvarez</p>
    </footer>
  );
}

export default Footer;
